<!-- 我的申请 -->

<template>
  <div class="apply">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="selectValue" placeholder="全部状态" @change="handleSelect" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="barCode" placeholder="搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="searchValue" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="table_list globle_table">
        <el-table :data="tableData" style="width: 100%;" max-height="540" :cell-style="reviewRowClass">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="serialId" label="商品编号"
            width="200"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"
            width="200"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="oldPrice" label="商品价格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="newPrice" label="新价格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="adjustReason" label="调价原因"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="申请时间" width="150">
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="auditTime" label="审核时间" width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.auditTime ? scope.row.auditTime : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" prop="status" label="审核状态">
            <template slot-scope="scope">
              <span>{{ scope.row.status == 0 ? '待审核' : scope.row.status == 1 ? '审核通过' : '审核不通过' }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="refuseReason" label="不通过理由"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip label="操作" header-align="center" align="center"
            width="190" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
              <el-button type="danger" plain v-if="scope.row.status == '0'"
                @click="handleRevoke(scope.row)">撤销</el-button>
              <el-button type="primary" plain v-if="scope.row.status == '2'"
                @click="handleReplied(scope.row)">重新申请</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 申请调价弹窗 -->
    <el-dialog title="重新申请调价" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="productName">
              <el-input v-model="ruleForm.productName" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="商品分类" prop="category">
              <el-input v-model="ruleForm.category" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="招标价格" prop="tenderProductPrice">
              <el-input v-model="ruleForm.tenderProductPrice" placeholder="自动带出" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品条形码" prop="barCode">
              <el-input v-model="ruleForm.barCode" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="scale">
              <el-input v-model="ruleForm.scale" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="新价格" prop="newPrice">
              <el-input v-model="ruleForm.newPrice" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="调价原因" prop="reason">
              <el-input v-model="ruleForm.reason" placeholder="请输入" type="textarea" maxlength="20"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">申请调价</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      barCode: '',
      // 下拉框数据
      selectValue: '',
      options: [{
        value: '0',
        label: '待审核'
      }, {
        value: '2',
        label: '不通过'
      }, {
        value: '1',
        label: '审核通过'
      },],
      searchValue: '', // 搜索数据
      // 表格数据
      tableData: [],
      // 重新申请弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        productName: '',  // 商品名称
        barCode: '',  // 条形码
        category: '',  // 商品分类
        scale: '',  // 规格
        tenderProductPrice: '',  // 招标价格
        newPrice: '',  // 新价格
        reason: '',  // 调价原因
        categoryId: '', // 分类id
        categoryName: '', // 分类Name
      },
      rules: {
        newPrice: [{ required: true, message: '请输入新价格', trigger: 'blur' }],
        reason: [{ required: true, message: '请输入调价原因', trigger: 'blur' }],
      },
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getMySubscribeList();
  },
  methods: {
    // 获取列表
    getMySubscribeList() {
      this.loading = true;
      this.$axios.get(this.$api.getMySubscribeList, {
        params: {
          productName: this.searchValue || null, // 商品名称
          barCode: this.barCode || null,
          status: this.selectValue || null, // 审核状态
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list.map(item => {
            if (!item.refuseReason) {
              item.refuseReason = '-';
            }
            return item;
          });
          this.totalItemsCount = res.data.result.totalCount;
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 重新申请
    handleReplied(row) {
      this.dialogVisible = true;
      const { productName, categoryName, categoryId, biddingPrice, barCode, specs } = row
      this.ruleForm.productName = productName;
      this.ruleForm.category = categoryName;
      this.ruleForm.categoryId = categoryId;
      this.ruleForm.tenderProductPrice = biddingPrice;
      this.ruleForm.barCode = barCode;
      this.ruleForm.scale = specs;
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.addProductAdjustPrice, {
            adjustReason: this.ruleForm.reason,
            categoryId: this.ruleForm.categoryId,
            newPrice: this.ruleForm.newPrice,
            oldPrice: this.ruleForm.tenderProductPrice,
            productName: this.ruleForm.productName,
            barCode: this.ruleForm.barCode,
            specs: this.ruleForm.scale,
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('申请成功!');
              this.handleCloseDialog();
              this.getMySubscribeList();
            }
          })
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },

    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 下拉选择
    handleSelect(value) {
      this.clearData();
      this.selectValue = value;
      this.getMySubscribeList();
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getMySubscribeList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getMySubscribeList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getMySubscribeList();
    },
    // 查看
    handleCheck(row) {
      this.$router.push({ path: "detail/my", query: { id: row.id }, });
    },
    // 撤销申请
    handleRevoke(row) {
      console.log(row);
      this.$confirm('确定要撤销申请吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(this.$api.deleteMySubscribe, {
          id: row.id,
        }).then((res) => {
          if (res.data.code == 100) {
            this.$message.success('撤销成功！')
            this.getMySubscribeList();
          }
        })
      }).catch(() => {
        this.$message.info('已取消撤销！')
      });
    },
    // 审核颜色变化
    reviewRowClass({ row, columnIndex }) {
      if (columnIndex == 8) {
        if (row.status == '0') {
          return { color: 'red' }
        }
      }
    },
  }

}



</script>

<style scoped lang="scss">
.apply {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin: 0 20px;
    }
  }

  // 审核不通过原因弹窗
  /deep/ .el-textarea__inner {
    resize: none;
    background-color: #f2f2f2;
    border-radius: 10px;
    height: 130px;
  }

  /deep/ .el-input__count {
    background-color: #f2f2f2;
  }

}
</style>
